import Cookies from 'universal-cookie';
import { StorageKey } from './storage-keys';
import { tokenStorage } from './token/token';

const cookies = new Cookies();

function saveToStorage(key: StorageKey, value: string) {
    cookies.set(key, value, {
        path: '/',
        secure: true,
    });
}

function getFromStorage(key: StorageKey) {
    return cookies.get(key);
}

function removeFromStorage(key: StorageKey) {
    cookies.remove(key, {
        path: '/',
        secure: true,
    });
}

export const storage = {
    save: saveToStorage,
    get: getFromStorage,
    remove: removeFromStorage,
    token: tokenStorage,
};
