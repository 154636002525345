import { ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import {
    resetExpandedFolders,
    resetFolderHierarchy,
} from '../slices/folderSlice';

export const StoreCleanerProvider = ({
    children,
}: {
    children: ReactElement;
}) => {
    const router = useRouter();
    const dispatch = useDispatch();

    const folderHierarchy = useSelector(
        (state: RootState) => state.folder.folderHierarchy,
    );

    //
    useEffect(() => {
        if (
            folderHierarchy.length > 0 &&
            !router.query.productId &&
            !router.query.foldeId
        ) {
            dispatch(resetFolderHierarchy());
        }

        if (!router.query.projectId) {
            dispatch(resetExpandedFolders());
        }
    }, [router]);

    return children;
};
