/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * Digit Mint Resources API
 * Digit-mint API v2
 * OpenAPI spec version: v2
 */
import { useQuery } from '@tanstack/react-query';
import type {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import type { GetAllUsers200, GetAllUsersParams, MeDTO } from '../../types';
import { customInstance } from '../../../config';

/**
 * The logged in user informations
 */
export const getMe = (signal?: AbortSignal) => {
    return customInstance<MeDTO>({ url: `/me`, method: 'get', signal });
};

export const getGetMeQueryKey = () => {
    return [`/me`] as const;
};

export const getGetMeQueryOptions = <
    TData = Awaited<ReturnType<typeof getMe>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({
        signal,
    }) => getMe(signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getMe>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>;
export type GetMeQueryError = unknown;

export const useGetMe = <
    TData = Awaited<ReturnType<typeof getMe>>,
    TError = unknown,
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetMeQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get all users
 * @summary Get all users
 */
export const getAllUsers = (
    params?: GetAllUsersParams,
    signal?: AbortSignal,
) => {
    return customInstance<GetAllUsers200>({
        url: `/users`,
        method: 'get',
        params,
        signal,
    });
};

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams) => {
    return [`/users`, ...(params ? [params] : [])] as const;
};

export const getGetAllUsersQueryOptions = <
    TData = Awaited<ReturnType<typeof getAllUsers>>,
    TError = unknown,
>(
    params?: GetAllUsersParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getAllUsers>>,
                TError,
                TData
            >
        >;
    },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUsers>>> = ({
        signal,
    }) => getAllUsers(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getAllUsers>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetAllUsersQueryResult = NonNullable<
    Awaited<ReturnType<typeof getAllUsers>>
>;
export type GetAllUsersQueryError = unknown;

/**
 * @summary Get all users
 */
export const useGetAllUsers = <
    TData = Awaited<ReturnType<typeof getAllUsers>>,
    TError = unknown,
>(
    params?: GetAllUsersParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getAllUsers>>,
                TError,
                TData
            >
        >;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetAllUsersQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};
