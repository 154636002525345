import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FocusedElementType = 'process' | 'flow';

interface FocusedElementState {
    focusedId: number | null;
    elementType: FocusedElementType | null;
}

// Slice
const initialState: FocusedElementState = {
    focusedId: null,
    elementType: null,
};

const focusedElementSlice = createSlice({
    name: 'focusedElement',
    initialState,
    reducers: {
        setFocusedElement: (
            state,
            action: PayloadAction<{ id: number; type: FocusedElementType }>,
        ) => {
            state.focusedId = action.payload.id;
            state.elementType = action.payload.type;
        },
        clearFocusedElement: (state) => {
            state.focusedId = null;
            state.elementType = null;
        },
    },
});

export const { setFocusedElement, clearFocusedElement } =
    focusedElementSlice.actions;

export default focusedElementSlice.reducer;
