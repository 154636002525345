import { extendTheme } from '@chakra-ui/react';
import { checkboxTheme } from './components/checkbox';

import { switchTheme } from './components/switch';
import { radioTheme } from './components/radio';
import { tableTheme } from './components/table';

export const theme = extendTheme({
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
    components: {
        Checkbox: checkboxTheme,
        Radio: radioTheme,
        Switch: switchTheme,
        Table: tableTheme,
    },
});
