import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { borderedTableStyle } from './bordered';
import { simpleTableStyle } from './simple';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    tableAnatomy.keys,
);

export const tableTheme = defineMultiStyleConfig({
    variants: { simple: simpleTableStyle, bordered: borderedTableStyle },
});
