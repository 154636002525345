import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    User,
} from '@firebase/auth';
import { firebaseConfig } from './config';
import { storage } from 'utils/storage/storage';
import { TokenKey } from 'utils/storage/token/token-keys';
import { AuthContextState } from './context';
import { MeDTO } from '../gateways/resources-api/lca/types';
import { getMe } from '../gateways/resources-api/lca/endpoints/users/users';
import { useDispatch } from 'react-redux';
import { setCurrentUserAndEntity } from '../redux/slices/userAndEntitySlice';
import {
    startSessionTracker,
    stopSessionTracker,
    identifySessionUser,
} from 'utils/session-tracking/logic';

export const useLogic = (): AuthContextState => {
    // Attributes
    const [isAuthStartupComplete, setIsAuthStartupComplete] =
        useState<boolean>(false);
    const [me, setMe] = useState<MeDTO | undefined>(undefined);
    const [isLogging, setIsLogging] = useState<boolean>(false);
    const [isResetPasswordLoading, setIsResetPasswordLoading] =
        useState<boolean>(false);
    const [isConfirmResetPasswordLoading, setIsConfirmResetPasswordLoading] =
        useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const isLogged = currentUser != null && me != null;
    const dispatch = useDispatch();

    async function refetchMe() {
        const me = await getMe();

        if (me) {
            if (me?.sessionRecordingEnabled) {
                startSessionTracker();
                identifySessionUser(me.email);
            } else {
                stopSessionTracker();
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const fcWidget = window?.fcWidget;
            if (fcWidget) {
                // To set unique user id in your system when it is available
                fcWidget.setExternalId(String(me.userId));

                // To set user name
                fcWidget.user.setFirstName(me.email);

                // To set user email
                fcWidget.user.setEmail(me.email);
            }
            dispatch(setCurrentUserAndEntity(me));
        }

        setMe(me);
    }

    // Functions
    async function signIn(email: string, password: string): Promise<void> {
        const auth = getAuth();
        setIsLogging(true);

        try {
            const data = await signInWithEmailAndPassword(
                auth,
                email,
                password,
            );

            // Save token in storage
            const token = await data.user.getIdToken();
            storage.token.save(TokenKey.ID_TOKEN, token);

            setCurrentUser(data.user);
            await refetchMe();
        } catch (err) {
            throw err;
        } finally {
            setIsLogging(false);
        }
    }

    async function resetPassword(email: string): Promise<void> {
        if (typeof window === 'undefined') {
            throw new Error('Internal error: unable to reset password');
        }
        const auth = getAuth();
        setIsResetPasswordLoading(true);

        try {
            const origin = window.location.origin;
            await sendPasswordResetEmail(auth, email, {
                url: `${origin}/auth/sign-in`, // On reset password success url -> continue url
            });
            setIsResetPasswordLoading(false);
        } catch (err) {
            setIsResetPasswordLoading(false);
            throw err;
        }
    }

    async function confirmResetPassword(
        oobCode: string,
        newPassword: string,
    ): Promise<void> {
        if (!oobCode && !newPassword) {
            throw new Error('Invalid oobCode or password');
        }
        const auth = getAuth();
        setIsConfirmResetPasswordLoading(true);

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            setIsConfirmResetPasswordLoading(false);
        } catch (err) {
            setIsConfirmResetPasswordLoading(false);
            throw err;
        }
    }

    async function signOut(): Promise<void> {
        const auth = getAuth();
        await auth.signOut();
        // Delete tokens
        storage.token.remove(TokenKey.ID_TOKEN);
        setMe(undefined);
        stopSessionTracker();
    }

    async function updateUserToken(force = false) {
        if (!currentUser) {
            storage.token.remove(TokenKey.ID_TOKEN);
            return;
        }
        try {
            const idToken = await currentUser.getIdToken(force);
            storage.token.save(TokenKey.ID_TOKEN, idToken);
            await refetchMe();
        } catch (err) {
            // unable to refresh token
            await signOut();
        } finally {
            setIsAuthStartupComplete(true);
        }
    }

    // forces the token refresh
    async function refreshToken() {
        // Check user is logged
        if (currentUser == null) {
            throw new Error('No user logged');
        }

        if (!isAuthStartupComplete) {
            throw new Error('Auth not ready yet');
        }

        // Get the refreshed id token
        await updateUserToken(true);
    }

    // Effects
    // Initialize provider client and subscribe to user states changes
    useEffect(() => {
        initializeApp(firebaseConfig);

        getAuth().onAuthStateChanged((user) => {
            if (!user) {
                setIsAuthStartupComplete(true);
                return;
            }
            setCurrentUser(user);
        });
    }, []);

    useEffect(() => {
        updateUserToken();
    }, [currentUser]);

    return {
        isAuthStartupComplete,
        isLogged,
        isLogging: isLogging,
        signIn,
        resetPassword,
        isResetPasswordLoading,
        confirmResetPassword,
        isConfirmResetPasswordLoading,
        signOut,
        refreshToken,
        me,
        refetchMe,
    };
};
