import { createSlice } from '@reduxjs/toolkit';

type FoldersState = {
    folderHierarchy: {
        id: number;
        name: string;
        parentIds?: [];
    }[];
    expandedFolders: number[];
};

const initialState: FoldersState = {
    folderHierarchy: [],
    expandedFolders: [],
};

export const folderSlice = createSlice({
    name: 'folder',
    initialState,
    reducers: {
        setFolderHierarchy: (state, action) => {
            state.folderHierarchy = action.payload;
        },
        resetFolderHierarchy: (state) => {
            state.folderHierarchy = [];
        },
        setExpandedFolders: (state, action) => {
            state.expandedFolders = action.payload;
        },
        toggleFolder: (state, action) => {
            const folderId = action.payload;
            const index = state.expandedFolders.indexOf(folderId);
            if (index >= 0) {
                state.expandedFolders.splice(index, 1); // Remove if it's already expanded
            } else {
                state.expandedFolders.push(folderId); // Add if it's not expanded
            }
        },
        setExpandedFoldersForBreadcrumb: (state, action) => {
            // Assuming action.payload is an array of folder IDs
            state.expandedFolders = action.payload;
        },

        resetExpandedFolders: (state) => {
            state.expandedFolders = [];
        },
    },
});

export const {
    setFolderHierarchy,
    resetFolderHierarchy,
    setExpandedFolders,
    toggleFolder,
    resetExpandedFolders,
    setExpandedFoldersForBreadcrumb,
} = folderSlice.actions;
export default folderSlice.reducer;
