import { createContext } from 'react';
import { MeDTO } from '../gateways/resources-api/lca/types';

export interface AuthContextState {
    isAuthStartupComplete: boolean;
    isLogging: boolean;
    isLogged: boolean;
    signIn: (email: string, password: string) => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    isResetPasswordLoading: boolean;
    confirmResetPassword: (
        oobCode: string,
        newPassword: string,
    ) => Promise<void>;
    isConfirmResetPasswordLoading: boolean;
    signOut: () => Promise<void>;
    refreshToken: () => Promise<void>;
    me: MeDTO | undefined;
    refetchMe: () => void;
}

export const AuthContext = createContext<AuthContextState | null>(null);
