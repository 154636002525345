import i18next from 'i18next';

export enum language {
    en = 'en',
    fr = 'fr',
    nl = 'nl',
}

export const languages = Object.keys(language);
export const defaultLanguage = language.en;

export function getCurrentLanguage(): language {
    const locale = i18next.language;
    const lang = locale?.split('-')?.[0];

    if (languages.includes(lang)) return lang as language;
    return defaultLanguage;
}

export function changeCurrentLanguage(language: language): void {
    if (languages.includes(language)) i18next.changeLanguage(language);
}
