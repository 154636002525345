import COLORS from 'ui/constants/colors';
import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
    track: {
        bg: 'gray.300',
        _checked: {
            bg: COLORS.Success.T500.value,
        },
    },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
