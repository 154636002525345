import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeDTO } from '../../gateways/resources-api/lca/types';

const initialState: MeDTO = {
    email: '',
    sessionRecordingEnabled: false,
    userId: 0,
    entityId: 0,
    isGlobalAdmin: false,
    role: null,
};

const userAndEntitySlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        setCurrentUserAndEntity: (state, action: PayloadAction<MeDTO>) => {
            return action.payload;
        },
    },
});

export const { setCurrentUserAndEntity } = userAndEntitySlice.actions;

export default userAndEntitySlice.reducer;
