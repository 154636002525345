import { storage } from '../storage';
import { TokenKey } from './token-keys';

const saveTokenInStorage = (token: TokenKey, value: string) => {
    storage.save(token, value);
};

const getTokenFromStorage = (token: TokenKey) => {
    return storage.get(token);
};

const removeTokenFromStorage = (token: TokenKey) => {
    storage.remove(token);
};

export const tokenStorage = {
    save: saveTokenInStorage,
    get: getTokenFromStorage,
    remove: removeTokenFromStorage,
};
