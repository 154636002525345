import { configureStore } from '@reduxjs/toolkit';
import folderReducer from './slices/folderSlice';
import userAndEntityReducer from './slices/userAndEntitySlice';
import actionedItemReducer from './slices/actionatedItemSlice';
import focusedElementReducer from './slices/focusedElementSlice';
const store = configureStore({
    reducer: {
        folder: folderReducer,
        userAndEntity: userAndEntityReducer,
        actionedModelItem: actionedItemReducer,
        focusedElement: focusedElementReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
