import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionTrackingProvider } from 'utils/session-tracking/provider';
import { StoreCleanerProvider } from '../redux/store-cleaner';
import { Provider as ReduxProvider } from 'react-redux';
import Head from 'next/head';
import { AuthProvider } from '../auth';
import { getI18nInstance } from 'i18n/locales';
import { theme } from 'ui/themes';
import { inter } from 'ui/constants/font-family';
import './tiptap.scss';
import store from '../redux/store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchIntervalInBackground: true,
            refetchOnReconnect: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
        },
    },
});

export default function App({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <title>Digit Mint</title>
                <link rel='icon' href='/assets/images/favicon.ico' />
                {process.env.NEXT_PUBLIC_FRESHCHAT_ENABLED === 'true' && (
                    <script src='/scripts/freshchat.js' async></script>
                )}
                <meta
                    name='viewport'
                    content='width=device-width, height=device-height, initial-scale:1, user-scalable=no'
                />
            </Head>
            <main className={inter.className}>
                <SessionTrackingProvider>
                    <I18nextProvider i18n={getI18nInstance()}>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools
                                initialIsOpen={false}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                client={queryClient}
                            />
                            <ReduxProvider store={store}>
                                <StoreCleanerProvider>
                                    <AuthProvider>
                                        <ChakraProvider theme={theme}>
                                            <Component {...pageProps} />
                                        </ChakraProvider>
                                    </AuthProvider>
                                </StoreCleanerProvider>
                            </ReduxProvider>
                        </QueryClientProvider>
                    </I18nextProvider>
                </SessionTrackingProvider>
            </main>
        </>
    );
}
