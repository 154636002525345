import React, { ReactElement, useEffect } from 'react';
import { createSessionTracker, trackSessionError } from './logic';

interface ISessionTrackingPropsProps {
    children: ReactElement;
}

/**
 * Boundary that tracks errors of the sessions.
 */
class ErrorBoundary extends React.Component<ISessionTrackingPropsProps> {
    componentDidCatch(error: Error, metadata?: any) {
        trackSessionError(error, metadata);
    }

    render() {
        return this.props.children;
    }
}

/**
 * Components that sets up session tracking instance.
 */
export const SessionTrackingProvider = (props: ISessionTrackingPropsProps) => {
    useEffect(() => {
        createSessionTracker();
    }, []);

    return <ErrorBoundary>{props.children}</ErrorBoundary>;
};
